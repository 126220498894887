import {useEffect, useState} from 'react'
import {DEFAULT_BACKEND_URL} from "../constants/default";
import axios from "axios";
import {ISession} from "../interfaces/ISession";
import defaultImage from "../assets/images/spider.svg"

export function useSession() {
  const defaultSession: ISession = {
    address: "",
    avatar: "",
    createdAt: new Date(),
    description: "",
    id: 0,
    name: "",
    platform_balance: 0,
    role: "",
    updatedAt: new Date(),
    wallet_balance: 0
  }
  const [session, setSession] = useState<ISession>(defaultSession)
  const [sessionError, setSessionError] = useState()
  const [avatar, setAvatar] = useState(defaultImage)

  const url = `${DEFAULT_BACKEND_URL}/session`;
  const fetchData = async () => {
    try {
      const res = await axios.get(url, { withCredentials: true })
      if (res.data.address) {
        setSession(res.data)
      } else  {
        setSession(defaultSession)
        setSessionError(res.request)
      }
      if (res.data.avatar) {
        axios.get(`${DEFAULT_BACKEND_URL}/users/${res.data.address}/downloadAvatar`, {responseType: 'arraybuffer'}).then(image => {
          let raw = Buffer.from(image.data).toString('base64');
          const base64 =  "data:" + image.headers["content-type"] + ";base64,"+raw;
          setAvatar(base64)
        })
      }
    } catch (error:any) {
      setSessionError(error)
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData().then();
    }, 1000);

    return () => clearTimeout(timer);
  }, [session, sessionError, avatar]);

  return { session , sessionError, avatar }
}
