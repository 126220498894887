import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import Header from "../components/Header";
import Home from "./Home";
import Profile from "./Profile";
import Games from "./Games";
import Footer from "../components/Footer";
import {UAParser} from "ua-parser-js";
import {
    useNetwork,
    useAccount,
    useSigner,
    useContractRead,
    useBalance,
    usePrepareContractWrite,
    useContractWrite, useWaitForTransaction, useContractEvent, useSignMessage
} from "wagmi";
import {DEFAULT_BACKEND_URL} from "../constants/default";
import {SiweMessage} from "siwe";
import {SPIDERX} from "../constants/spiderx";
import {BigNumber, ethers} from "ethers";
import {BUSD} from "../constants/busd";
import axios from "axios";
import {AlertContainer} from "../components/alerts/AlertContainer";
import {useAlert} from "../context/AlertContext";

type MainProps = {
    session: any,
    avatar: any
}

function Main({
    session, avatar
                }: MainProps) {

    const parser = new UAParser()
    const browser = parser.getBrowser()
    const device = parser.getDevice()

    const { address, isConnecting, isDisconnected, isConnected } = useAccount();
    const { data: signer } = useSigner()
    const { chain } = useNetwork()

    const [gameBalance, setGameBalance] = useState("0")
    const [balance, setBalance] = useState("0")
    const [transferArg, setTransferArg] = useState<BigNumber>(ethers.utils.parseEther("0"))
    const [withdrawArg, setWithdrawArg] = useState<BigNumber>(ethers.utils.parseEther("0"))
    const { signMessageAsync } = useSignMessage()

    const { showError: showErrorAlert, showSuccess: showSuccessAlert } = useAlert()

    console.log(DEFAULT_BACKEND_URL)

    useBalance({
        address: `0x${session.address.slice(2)}`,
        token: `0x${BUSD.address.slice(2)}`,
        watch: true,
        onSuccess(data) {
            const newBalance = parseFloat(data.formatted).toFixed(2)
            if (newBalance != balance) {
                setBalance(newBalance)
            }
        },
    })

    useContractRead({
        abi: SPIDERX.abi,
        address: `0x${SPIDERX.address.slice(2)}`,
        functionName: 'balances',
        args: [address ? address : session.address],
        watch: true,
        onSuccess(data) {
            if (data) {
                const newBalance = ethers.utils.formatEther(data.toString())
                if (newBalance != gameBalance) {
                    setGameBalance(newBalance)
                }
            }
        },
    })

    const { config: transferConfig } = usePrepareContractWrite({
        address: `0x${BUSD.address.slice(2)}`,
        abi: BUSD.abi,
        functionName: 'transfer',
        args: [SPIDERX.address, transferArg]
    })

    const { data: transferData, writeAsync: transfer = async () => {} } = useContractWrite({
        ...transferConfig,
        onSuccess(data) {
            console.log('Success', transferData)
        },
    })
    const deposit = async () => {
        const tx = await transfer()
        const res = await axios.post<any>(`${DEFAULT_BACKEND_URL}/transactions/deposit`, tx)
        if (res.data.reason) console.log(res)
    }

    const { config: withdrawConfig } = usePrepareContractWrite({
        address: `0x${SPIDERX.address.slice(2)}`,
        abi: SPIDERX.abi,
        functionName: 'withdraw',
        args: [withdrawArg]
    })

    const { data: withdrawData, writeAsync: withdraw = async () => {} } = useContractWrite(withdrawConfig)
    const withdrawBusd = async () => {
        await withdraw()
    }

    useWaitForTransaction({
        hash: transferData?.hash,
        confirmations: 1,
        timeout: 30_000, // 30 seconds
        onSuccess(data) {
            showSuccessAlert(`Deposit successfully!`)
        },
        onError(data) {
            showErrorAlert(`Deposit failed! ${data}`)
        },
    })

    useWaitForTransaction({
        hash: withdrawData?.hash,
        confirmations: 1,
        timeout: 30_000, // 30 seconds
        onSuccess(data) {
            showSuccessAlert(`Withdraw successfully!`)
        },
        onError(data) {
            showErrorAlert(`Withdraw failed! ${data}`)
        },
    })

    useContractEvent({
        address: `0x${SPIDERX.address.slice(2)}`,
        abi: SPIDERX.abi,
        eventName: 'NicknameChange',
        listener(userAddress) {
            showSuccessAlert(`Name changed successfully!`)
        },
    })

    useContractEvent({
        address: `0x${SPIDERX.address.slice(2)}`,
        abi: SPIDERX.abi,
        eventName: 'AvatarChange',
        listener(userAddress) {
            showSuccessAlert(`Avatar changed successfully!`)
        },
    })

    const createSiweMessage = async function (address: string | undefined, statement: any) {
        const res = await fetch(`${DEFAULT_BACKEND_URL}/nonce`, {
            credentials: 'include',
        });
        const message = new SiweMessage({
            domain: window.location.host,
            address,
            statement,
            uri: window.location.origin,
            version: '1',
            chainId: chain?.id,
            nonce: await res.text()
        });
        console.log('nonce: ', message.nonce)
        return message.prepareMessage();
    }

    const signInWithEthereum = async function() {
        try {
            const message = await createSiweMessage(
                address,
                'Sign in with Ethereum to the app.'
            );
            const signature = await signMessageAsync({message})
            console.log("message: ", message)
            console.log("signature: ", signature)
            const res = await fetch(`${DEFAULT_BACKEND_URL}/verify`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message, signature }),
                credentials: 'include'
            });
            console.log("old siwe:", res)
            const data = await res.json()
            if (data.address == address) {
                console.log('authenticated: ', data)
            } else {
                console.log('non authenticated: ', data)
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-black via-slate-900 to-cyan-700">
            {/*<Routes>*/}
            {/*    <Route path='/login' element={<Login/>} />*/}
            {/*</Routes>*/}
            <div className="container mx-auto px-2 sm:px-4 py-2.5 rounded">
                <AlertContainer />
                <Header address={address} session={session} signInWithEthereum={signInWithEthereum} />
                <Routes>
                    <Route path='/' element={<Home/>} />
                    <Route path='/profile' element={<Profile
                        isConnected={isConnected}
                        session={session}
                        balance={balance}
                        gameBalance={gameBalance}
                        avatar={avatar}
                        deposit={deposit}
                        setTransferArg={setTransferArg}
                        withdraw={withdrawBusd}
                        setWithdrawArg={setWithdrawArg}
                    />} />
                    <Route path='/games' element={<Games/>} />
                </Routes>
                <Footer/>
            </div>
        </div>
    )
}

export default Main
