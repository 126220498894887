import chess from "../assets/images/games/game1.png"
import wordle from "../assets/images/games/game2.png"
import checkers from "../assets/images/games/game3.png"
import pinochle from "../assets/images/games/game4.png"
import React from "react";
import {useNavigate} from "react-router-dom";

type GamesProps = {
}

function Games({
                }: GamesProps) {
    const navigate = useNavigate()
    return (
       <div className="text-gray-300">
           <h1 className="text-center mb-4 text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl dark:text-white">
           Games</h1>
           <div
               className="relative mb-5 flex px-4 py-4 border border-gray-400 rounded-lg shadow items-center justify-between dark:bg-gray-800">
               <button type="button" data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                       className="right-0 top-0 absolute ml-2 rounded-full mr-2 hover:bg-gray-800 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                   <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"></path>
                   </svg>
               </button>

               <div className="text-center flex justify-between items-center">
                   <div>
                       <img src={wordle} alt="wordle-icon"/>
                       <p>Wordle</p>
                   </div>
               </div>
               <div className="text-center flex justify-between items-center">
                   <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path>
                   </svg> {0}
                   <a
                        type="button" href={process.env.NODE_ENV === "development" ? "https://192.168.100.9:3001/" : "https://wordle.spiderx.io/"}
                           className="ml-2 w-[120px] rounded-full text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                       <div className="flex items-center justify-center ">
                           <p>Play</p>
                           <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                               <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"></path></svg>
                       </div>
                       </a>
               </div>
           </div>

           <div
               className="blur-sm relative mb-5 flex px-4 py-4 rounded-lg border border-gray-400 shadow items-center justify-between dark:bg-gray-800">
               <button type="button" data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                       className="right-0 top-0 absolute ml-2 rounded-full mr-2 hover:bg-gray-800 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                   <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"></path>
                   </svg>
               </button>
               <div className="text-center flex justify-between items-center">
                   <div>
                       <img src={chess} alt="chess-icon"/>
                       <p>Chess</p>
                   </div>
               </div>
               <div className="text-center flex justify-between items-center">
                   <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path>
                   </svg> {0}
                   <button type="button"
                           className="ml-2 w-[120px] rounded-full text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                       <div className="flex items-center justify-center ">
                           <p>Play</p>
                           <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                               <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"></path></svg>
                       </div>
                   </button>
               </div>
           </div>

           <div
               className="blur-sm relative mb-5 flex px-4 py-4 border border-gray-400 rounded-lg shadow items-center justify-between dark:bg-gray-800">
               <button type="button" data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                       className="right-0 top-0 absolute ml-2 rounded-full mr-2 hover:bg-gray-800 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                   <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"></path>
                   </svg>
               </button>
               <div className="text-center flex justify-between items-center">
                   <div>
                       <img className="w-[67px]" src={checkers} alt="checkers-icon"/>
                       <p>Checkers</p>
                   </div>
               </div>
               <div className="text-center flex justify-between items-center">
                   <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path>
                   </svg> {0}
                   <button type="button"
                           className="ml-2 w-[120px] rounded-full text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                       <div className="flex items-center justify-center ">
                           <p>Play</p>
                           <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                               <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"></path></svg>
                       </div>
                   </button>
               </div>
           </div>

           <div
               className="blur-sm relative mb-5 flex px-4 py-4 border border-gray-400 rounded-lg shadow items-center justify-between dark:bg-gray-800">
               <button type="button" data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                       className="right-0 top-0 absolute ml-2 rounded-full mr-2 hover:bg-gray-800 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                   <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"></path>
                   </svg>
               </button>
               <div data-popover id="popover-user-profile" role="tooltip"
                    className="absolute z-10 invisible inline-block w-64 text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-600">
                    <p>test</p>
               </div>
               <div className="text-center flex justify-between items-center">
                   <div>
                       <img className="w-[67px]" src={pinochle} alt="pinochle-icon"/>
                       <p>Pinochle</p>
                   </div>
               </div>
               <div className="text-center flex justify-between items-center">
                   <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path>
                   </svg> {0}
                   <button type="button"
                           className="ml-2 w-[120px] rounded-full text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                       <div className="flex items-center justify-center ">
                           <p>Play</p>
                           <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                               <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"></path></svg>
                       </div>
                   </button>
               </div>
           </div>
       </div>
    )
}

export default Games
