export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 4000
export const LONG_ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = true
export const BLOWFISH_KEY = 'xcQUAHsik#Thq&LG*8es2DsZ$3bw^e'
export const BLOWFISH_IV = '#45XmF^w'
export const ENABLE_ARCHIVED_GAMES = false
