import {BaseModal} from "./BaseModal";
import {useEffect, useState} from "react";
import busd from "../../assets/images/busd.svg"
import {useAlert} from "../../context/AlertContext";
import {AlertContainer} from "../alerts/AlertContainer";

type Props = {
    isOpen: boolean
    handleClose: () => void
    changeFunction: string
    setChangeNameIsApproved: Function
    setChangeAvatarIsApproved: Function
}

export const ChangeProfileModal = ({isOpen, handleClose, changeFunction, setChangeNameIsApproved, setChangeAvatarIsApproved}: Props) => {

  return (

      <BaseModal title={"Profile change"} isOpen={isOpen} handleClose={handleClose}>
          <div className="mt-5">
              <p className="mb-5">Changing profile data is a paid service! For each parameter, a fee of 1 BUSD is charged.</p>
              <div className="flex justify-around">
                  <button type="button"
                          onClick={() => {
                              {
                                  changeFunction === "name" ? setChangeNameIsApproved(false) : setChangeAvatarIsApproved(false)
                                  handleClose()
                              }
                            }
                          }
                          className="rounded-full w-[120px] flex items-center justify-center text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                      Cancel
                  </button>
                  <button type="button"
                          onClick={() => {
                              changeFunction === "name" ? setChangeNameIsApproved(true) : setChangeAvatarIsApproved(true)
                              handleClose()
                            }
                          }
                          className="rounded-full w-[120px] flex items-center justify-center text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                      Change
                  </button>
              </div>
          </div>
      </BaseModal>
  )
}
