import busd from "../assets/images/busd.svg"
import wordle from "../assets/images/games/game2.png"
import chess from "../assets/images/games/game1.png"
import {ISession} from "../interfaces/ISession";
import defaultAvatar from "../assets/images/spider.svg"
import {useEffect, useRef, useState} from "react";
import User from "../models/User";
import {DepositModal} from "../components/modals/DepositModal";
import {WithdrawModal} from "../components/modals/WithdrawModal";
import {AlertContainer} from "../components/alerts/AlertContainer";
import {useAlert} from "../context/AlertContext";
import {ChangeProfileModal} from "../components/modals/ChangeProfileModal";
import {BigNumber, ethers} from "ethers";

type ProfileProps = {
    isConnected: boolean
    session: ISession,
    balance: string
    gameBalance: string
    avatar: any
    deposit: Function
    withdraw: Function
    setTransferArg: Function
    setWithdrawArg: Function
}

function Profile({
    isConnected,
    session,
    gameBalance,
    avatar,
    balance,
    deposit,
    withdraw,
    setTransferArg,
    setWithdrawArg
                }: ProfileProps) {

    const [name, setName] = useState(session.name)
    const [isEditName, setIsEditName] = useState(false)
    const [nameInputValue, setNameInputValue] = useState("")
    const [description, setDescription] = useState(session.description)
    const [isEditDescription, setIsEditDescription] = useState(false)
    const [descriptionInputValue, setDescriptionInputValue] = useState("")
    const fileInput = useRef<HTMLInputElement>(null);
    const [depositModalIsOpen, setDepositModalIsOpen] = useState(false)
    const [withdrawModalIsOpen, setWithdrawModalIsOpen] = useState(false)
    const [changeModalIsOpen, setChangeModalIsOpen] = useState(false)
    const [changeNameIsApproved, setChangeNameIsApproved] = useState(false)
    const [changeAvatarIsApproved, setChangeAvatarIsApproved] = useState(false)
    const { showError: showErrorAlert, showSuccess: showSuccessAlert } = useAlert()
    const changePrice = 1

    const changeAvatar = async () => {
        fileInput.current?.click()
    };

    const handleChangeAvatar = async () => {
        if (parseFloat(gameBalance) < changePrice) {
            return showErrorAlert("Insufficient funds!")
        }
        const avatar = fileInput.current?.files?.item(0)
        if (avatar && changeAvatarIsApproved) {
            await handleUploadClick(avatar)
            setChangeAvatarIsApproved(false)
        } else {
            setChangeModalIsOpen(true)
            setChangeAvatarIsApproved(false)
        }
    }

    const handleUploadClick = async (avatar: File) => {
        // setIsLoading(true)
        try {
            if (!avatar) {
                return;
            }
            let formData = new FormData();
            formData.append("file", avatar);
            const res = await User.setAvatar(session.address, formData)
            console.log("res: ", res)
            if (res.data.code) {
                console.log(res.data.code)
                alert(res.data.code)
                // setIsLoading(false)
                setNameInputValue(name)
                return
            }
        } catch (e) {
            console.error(e)
            setTimeout(handleUploadClick, 1000)
        }
    };
    const handleEditName = () => {
        if (parseFloat(gameBalance) < changePrice) {
            return showErrorAlert("Insufficient funds!")
        }
        if (nameInputValue && changeNameIsApproved) {
            setIsEditName(false)
            setName(nameInputValue)
            User.setNickname(session.address, nameInputValue).then(r => {
                console.log(r)
                setChangeNameIsApproved(false)
            })
        } else {
            setChangeModalIsOpen(true)
        }
    }

    const handleEditDescription = () => {
        if (descriptionInputValue) {
            setIsEditDescription(false)
            setDescription(descriptionInputValue)
            User.setDescription(session.address, descriptionInputValue).then(r => {
                console.log(r)
            })
        }
    }

    useEffect(() => {
        if (session.name !== name) {
            setName(session.name)
        }
        if (session.description !== description) {
            setDescription(session.description)
        }
    }, [session.name , session.description])

    useEffect(() => {
        if (session.address){
            fileInput.current?.addEventListener('change', handleChangeAvatar)
        }
    }, [session.address]);

    useEffect(() => {
        if (changeNameIsApproved) handleEditName()
        if (changeAvatarIsApproved) handleChangeAvatar().then()
    }, [changeNameIsApproved, changeAvatarIsApproved])

    return (
       <div className="text-gray-200">
           <ChangeProfileModal setChangeAvatarIsApproved={setChangeAvatarIsApproved} setChangeNameIsApproved={setChangeNameIsApproved} changeFunction={isEditName ? "name" : "avatar"} isOpen={changeModalIsOpen} handleClose={() => setChangeModalIsOpen(false)}/>
           <DepositModal isConnected={isConnected} deposit={deposit} setTransferArg={setTransferArg} balance={balance} isOpen={depositModalIsOpen} handleClose={() => setDepositModalIsOpen(false)}/>
           <WithdrawModal isConnected={isConnected} withdraw={withdraw} setWithdrawArg={setWithdrawArg} gameBalance={gameBalance} isOpen={withdrawModalIsOpen} handleClose={() => setWithdrawModalIsOpen(false)}/>
           <div
               className="mb-5 flex py-4 border border-gray-400 rounded-lg shadow items-center justify-center dark:bg-gray-800">
               <div>
                   <div className="flex flex-wrap justify-center items-center">
                       <div className="relative mr-5">
                           {/*<div className="bg-gradient-to-r from-cyan-500 to-blue-500 w-[140px] h-[140px] rounded-full">*/}
                           {/*    <img className="fill-blue-500 w-[140px] h-[140px] rounded-full"*/}
                           {/*         src={avatar} alt="user avatar"/>*/}
                           {/*</div>*/}
                           <div className="flex justify-center items-center bg-gradient-to-r from-cyan-500 to-blue-500 inline-block overflow-hidden rounded-full w-[140px] h-[140px]">
                               <img className="fill-blue-500" src={avatar} alt="user avatar" />
                           </div>
                           <div className="flex justify-center items-center bottom-0 right-0 absolute w-7 h-7 dark:border-gray-800 rounded-full">
                               <input type="file" ref={fileInput}/>
                               <button type="button" onClick={() => changeAvatar()} data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                                       className="ml-2 rounded-full mr-2 hover:bg-gray-600 border border-gray-400 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                                   <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                       <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"></path>
                                   </svg>
                               </button>
                           </div>
                       </div>
                       <div className="mr-5">
                           <div className="flex justify-center items-center mt-2 mb-2">
                               {isEditName ? (
                                   <>
                                       <button type="button" onClick={() => setIsEditName(false)} data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                                               className="ml-2 rounded-full mr-2 hover:bg-gray-600 border border-gray-400 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                                           <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                               <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                           </svg>
                                       </button>
                                       <input type="name" id="name"
                                              onChange={(event) => setNameInputValue(event.target.value)}
                                              className="text-gray-600 ml-2 rounded-full mr-2 border border-yellow-600 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700"
                                              placeholder={name} required
                                       />
                                       <button type="button" onClick={() => handleEditName()} data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                                               className="ml-2 rounded-full mr-2 hover:bg-gray-600 border border-gray-400 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                                           <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                               <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                                           </svg>
                                       </button>
                                   </>
                               ) : (
                                   <>
                                       <p className="font-press-start">
                                           {name}
                                       </p>
                                       <button type="button" onClick={() => setIsEditName(true)} data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                                               className="ml-2 rounded-full mr-2 hover:bg-gray-600 border border-gray-400 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                                           <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                               <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"></path>
                                           </svg>
                                       </button>
                                   </>
                                   )}
                           </div>
                           <div className="flex justify-center items-center">
                               <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"></path>
                               </svg>
                               <p>{session.address.slice(0, 6) + "...." + session.address.slice(-5)}</p>
                           </div>
                           <div className="flex justify-center items-center mb-2 mr-5">
                               <img className="w-4 h-4 mr-2" src={busd} alt="busd-logo"/>
                               <p>{gameBalance.slice(0, 5)}</p>
                           </div>
                       </div>
                       <div className="mt-5">
                           <button type="button"
                                   onClick={() => setDepositModalIsOpen(true)}
                                   className="w-[180px] flex items-center justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                               Add Money
                               <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                               </svg>
                           </button>
                           <button type="button"
                                   onClick={() => setWithdrawModalIsOpen(true)}
                                   className="w-[180px] flex items-center justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                               Withdraw
                               <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                               </svg>
                           </button>
                       </div>
                   </div>
                   <div className="ml-2 mt-5">
                       <div className="flex items-center text-center">
                           <label htmlFor="message" className="block text-sm font-medium dark:text-white">
                               Description
                           </label>
                           {!isEditDescription && (
                               <button type="button" onClick={() => setIsEditDescription(true)} data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                                       className="ml-2 rounded-full mr-2 hover:bg-gray-600 border border-gray-400 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                                   <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                       <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"></path>
                                   </svg>
                               </button>
                           )}
                       </div>
                       {isEditDescription ? (
                           <div className="flex items-start">
                               <button type="button" onClick={() => setIsEditDescription(false)} data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                                       className="ml-2 rounded-full mr-2 hover:bg-gray-600 border border-gray-400 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                                   <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                       <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                   </svg>
                               </button>
                               <textarea id="message" rows={4}
                                         onChange={(event) => setDescriptionInputValue(event.target.value)}
                                         className="text-gray-600 p-2.5 w-full text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                         placeholder={description}>
                               </textarea>
                               <button type="button" onClick={() => handleEditDescription()} data-modal-target="crypto-modal" data-modal-toggle="crypto-modal"
                                       className="ml-2 rounded-full mr-2 hover:bg-gray-600 border border-gray-400 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700">
                                   <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                       <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                                   </svg>
                               </button>

                           </div>
                       ) : (
                           <p>{description}</p>
                       )}
                   </div>
               </div>
           </div>
           <div>
               <div className="rounded-lg border border-gray-400 relative overflow-x-auto">
                   <label htmlFor="message" className="ml-2 mt-2 block mb-2 text-sm font-medium dark:text-white">
                       Latest games
                   </label>
                   <table className="w-full text-sm text-left dark:text-gray-400">
                       <thead
                           className="text-[10px] uppercase dark:bg-gray-700 dark:text-gray-400">
                       <tr>
                           <th scope="col" className="px-3 py-3 rounded-l-lg">
                               Game
                           </th>
                           <th scope="col" className="px-3 py-3">
                               Start time
                           </th>
                           <th scope="col" className="px-3 py-3 rounded-r-lg">
                               Bet
                           </th>
                           <th scope="col" className="px-3 py-3 rounded-r-lg">
                               Duration
                           </th>
                           <th scope="col" className="px-3 py-3 rounded-r-lg">
                               Total
                           </th>
                       </tr>
                       </thead>
                       <tbody>
                       <tr className="dark:bg-gray-800">
                           <th scope="row"
                               className="px-3 py-4 font-medium whitespace-nowrap dark:text-white">
                               <div className="flex items-center">
                                   <img className="mr-1 w-5 h-5" src={wordle} alt="wordle-icon"/>
                                   <p>Wordle</p>
                               </div>
                           </th>
                           <td className="px-3 py-4">
                               19:30
                           </td>
                           <td className="px-3 py-4">
                               $10
                           </td>
                           <td className="px-3 py-4">
                               30min
                           </td>
                           <td className="px-3 py-4">
                               Win
                           </td>
                       </tr>
                       <tr className="dark:bg-gray-800">
                           <th scope="row"
                               className="px-3 py-4 font-medium whitespace-nowrap dark:text-white">
                               <div className="flex items-center">
                                   <img className="mr-1 w-5 h-5" src={chess} alt="chess-icon"/>
                                   <p>Chess</p>
                               </div>
                           </th>
                           <td className="px-3 py-4">
                               15:01
                           </td>
                           <td className="px-3 py-4">
                               $24
                           </td>
                           <td className="px-3 py-4">
                               15min
                           </td>
                           <td className="px-3 py-4">
                               Lose
                           </td>
                       </tr>
                       </tbody>
                   </table>
               </div>
           </div>
       </div>
    )
}

export default Profile
