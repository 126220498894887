import axios from "axios";
import {DEFAULT_BACKEND_URL} from "../constants/default";

export default class User {
    public static nickname = ''
    public static address = ''
    public static avatar = ''
    public static solution = ''

    public static async setNickname(address: string, nameInputValue: string){
        return await axios.patch<any>(`${DEFAULT_BACKEND_URL}/users/${address}/updateNickname`, {name: nameInputValue})
    }

    public static async setDescription(address: string, descriptionInputValue: string){
        return await axios.patch<any>(`${DEFAULT_BACKEND_URL}/users/${address}/updateDescription`, {description: descriptionInputValue})
    }

    public static async setAvatar(address: string, formData: any){
        return await axios.post<any>(`${DEFAULT_BACKEND_URL}/users/${address}/uploadAvatar`, formData)
    }

    public static async deposit(amount: string){

    }

    public static async withdraw(address: string, formData: any){
        return await axios.post<any>(`${DEFAULT_BACKEND_URL}/users/${address}/uploadAvatar`, formData)
    }

    // public static async getSolution(hash: string, address: string){
    //     const room: IRoom = await axios.get(`${DEFAULT_BACKEND_URL}/rooms/${hash}`)
    //     room.players.forEach(player => {
    //         if (player.address === address){
    //             this.solution = player.solution
    //         }
    //     })
    //     return solution
    // }

    // public static async getSolution(hash: string, address: string){
    //     const room: IRoom = await axios.get(`${DEFAULT_BACKEND_URL}/rooms/${hash}`)
    //     room.players.forEach(player => {
    //         if (player.address === address){
    //             this.solution = player.solution
    //         }
    //     })
    //     return solution
    // }
    // public static setSolution(solution: string){
    //    return this.solution = solution
    // }
    // public static setNickname(newNickname: string){
    //     this.nickname = newNickname
    // }
    //
    // public static setAddress(newAddress: string){
    //     this.address = newAddress
    // }
    //
    // public static setAvatar(newAvatar: string){
    //     this.avatar = newAvatar
    // }
}
