import {AuthenticationStatus, AvatarComponent, ConnectButton, darkTheme, midnightTheme} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import {getDefaultWallets, RainbowKitAuthenticationProvider, RainbowKitProvider} from '@rainbow-me/rainbowkit';
import {configureChains, createClient, useAccount, WagmiConfig} from 'wagmi';
import { bscTestnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';
import React, {useEffect, useState} from "react";
import {useSession} from "./hooks/useSession";
import Main from "./routes/Main";
import {DEFAULT_BACKEND_URL} from "./constants/default";

const authenticationAdapter = createAuthenticationAdapter({
    getNonce: async () => {
        const response = await fetch(`${DEFAULT_BACKEND_URL}/nonce`, {
            credentials: 'include',
        });
        return await response.text();
    },

    createMessage: ({ nonce, address, chainId }) => {
        return new SiweMessage({
            domain: window.location.host,
            address,
            statement: 'Sign in with Ethereum to the app.',
            uri: window.location.origin,
            version: '1',
            chainId,
            nonce,
        });
    },

    getMessageBody: ({ message }) => {
        return message.prepareMessage()
    },

    verify: async ({ message, signature }) => {
        console.log("message: ", message)
        console.log("signature: ", signature)
        const verifyRes = await fetch(`${DEFAULT_BACKEND_URL}/verify`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message, signature }),
            credentials: 'include'
        });

        console.log(verifyRes)

        return Boolean(verifyRes.ok);
    },

    signOut: async () => {
        await fetch(`${DEFAULT_BACKEND_URL}/logout`);
    },
});

const { chains, provider, webSocketProvider } = configureChains(
    [
        bscTestnet
    ],
    [publicProvider()]
);

const { connectors } = getDefaultWallets({
    appName: 'RainbowKit demo',
    chains,
});

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
});

const App = () => {

  const [authStatus, setAuthStatus] = useState<AuthenticationStatus>('loading')
    const {session, avatar} = useSession()
    const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
        return (
            <div className="flex justify-center items-center bg-gradient-to-r from-cyan-500 to-blue-500 inline-block overflow-hidden rounded-full w-[80px] h-[80px]">
                {ensImage ? (
                    <img className="fill-blue-500" src={ensImage} alt="ens avatar" />
                ) : (
                    <img className="fill-blue-500" src={avatar} alt="ens avatar" />
                )}
            </div>
        )
    };

    useEffect(() => {
        if (session.address) {
            setAuthStatus('authenticated')
        } else {
            setAuthStatus('unauthenticated')
        }
    }, [session])



  return (
      <WagmiConfig client={wagmiClient}>
          <RainbowKitAuthenticationProvider
              adapter={authenticationAdapter}
              status={authStatus}
          >
              <RainbowKitProvider theme={darkTheme()} avatar={CustomAvatar} chains={chains}>
                  <Main session={session} avatar={avatar} />
              </RainbowKitProvider>
          </RainbowKitAuthenticationProvider>
      </WagmiConfig>
  );
};

export default App;

