import {BaseModal} from "./BaseModal";
import {useState} from "react";
import busd from "../../assets/images/busd.svg"
import {useAlert} from "../../context/AlertContext";
import {AlertContainer} from "../alerts/AlertContainer";
import {ethers} from "ethers";

type Props = {
    isOpen: boolean
    handleClose: () => void
    gameBalance: string
    withdraw: Function
    isConnected: boolean
    setWithdrawArg: Function
}

export const WithdrawModal = ({isOpen, handleClose, gameBalance, withdraw, isConnected, setWithdrawArg}: Props) => {

    const [rangeInputValue, setRangeInputValue] = useState("0")
    const { showError: showErrorAlert, showSuccess: showSuccessAlert } = useAlert()

    const handleInputRange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRangeInputValue(e.target.value)
    }

    const handleWithdrawMoney = (amount: string) => {
        if (!isConnected) return showErrorAlert("Please connect your wallet")
        if (parseFloat(amount) > parseFloat(gameBalance) || parseFloat(amount) <= 0) {
            return showErrorAlert("Insufficient funds!")
        }
        withdraw(amount)
        handleClose()
    }

  return (
      <BaseModal title={"Withdraw money"} isOpen={isOpen} handleClose={handleClose}>
          <div className="mt-5">
              <div className="flex justify-start items-center">
                  <label htmlFor="company"
                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Available: {gameBalance}</label>
              </div>
              <div className="mb-2 flex justify-between rounded-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <input id="company"
                         onChange={event => {
                             setRangeInputValue(event.target.value)
                             setWithdrawArg(ethers.utils.parseEther(event.target.value))
                         }
                         }
                         className="focus:none text-gray-900 text-sm rounded-lg block w-full"
                         placeholder={rangeInputValue} required
                  />
                  <img className="ml-2" src={busd} alt="busd icon"/>
              </div>
              <input id="customRange1" type="range" onChange={event => {
                  setRangeInputValue(event.target.value)
                  setWithdrawArg(ethers.utils.parseEther(event.target.value))
              }
              } value={rangeInputValue} min={0} max={gameBalance} step={0.1}
                     className="mb-5 form-range appearance-none w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"/>
              <div className="flex justify-around">
                  <button type="button"
                          onClick={() => {}}
                          className="rounded-full w-[120px] flex items-center justify-center text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                      Cancel
                  </button>
                  <button type="button"
                          onClick={() => handleWithdrawMoney(rangeInputValue)}
                          className="rounded-full w-[120px] flex items-center justify-center text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                      Withdraw
                  </button>
              </div>
          </div>
      </BaseModal>
  )
}
